@font-face{
  font-family: 'Switzer';
  font-weight: normal;
  src: url('../public/fonts/Switzer-Regular.woff2') format('woff2');
}
@font-face{
  font-family: 'Switzer';
  font-weight: bold;
  src: url('../public/fonts/Switzer-Bold.woff2') format('woff2');
}
:root{
  --black: #020102;
  --dark: #1D1D1E;
  --dark-grey: #3E433F;
  --light-grey: #E5E9E3;
  --grey: #5F6862;
  --green: #57BE6E;
  --white: #F7FDF7;
  --Polkadot: #E6007A;
  --Kusama: #000000;
  --Polimec: #CAFFA0;
  --Moonriver: #53CBC8;
  --basilisk: #4EFFB0;
  --bifrost: #0BB0F8;
  --parallel: #7E4DE5;
  --acala: #E40C5B;
}
::selection{
  color: var(--white);
  background: var(--green);
}

html{
  font-size: 16px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

*{
  margin: 0;
  padding: 0;

}

h1,h2,h3,h4, h5, p{
  font-family: 'Switzer'; 
  color: var(--white);
  font-weight: normal;
  line-height: 110%;
}
a{
  text-decoration: none;
}
h1{
  font-size: 4rem;
  letter-spacing: -0.12rem;
  overflow: hidden;

}
h2{
  font-size: 3rem;
  letter-spacing: -0.06rem;
  overflow: hidden;
}
h3{
  font-size: 2.25rem;
  margin-bottom: 1rem;
  letter-spacing: -0.05rem;
  overflow: hidden;
}
h3.body{
  color: var(--grey);
  line-height: 125%;
  margin-top: 1rem;
}
h4{
  font-family: 'Switzer';
  font-weight: bold;
  font-size: 1rem;
  color: var(--dark-grey);
  text-transform: uppercase;
}
h5{
  font-size: 1rem;
}
p{
  font-size: 1.25rem;
  line-height: 150%;
  font-weight: 500;
  z-index: 1;
}
a{
  font-size: 1.25rem;
}
p.label{
  font-family: 'Switzer';

  font-size: 0.75rem;
  color: var(--grey);
}
.alt{
  color: var(--green);
}
p.alt{
  color: var(--grey);
}
.abs{
  position: absolute;
}
.abs.center{
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

}
.abs.bottom{
  bottom: 0rem;
}
.abs.left{
  left: 0rem;
}
.abs.right{
  right: 0rem;
}
.abs.top{
  top: 0rem;
}
.abs.align-right{
  right: 5.5rem;
}



.letter{
  transform: translateY(100px);
  display: inline-block;
  line-height: 2rem;
  transform-origin: 0%;
}
.letter-wrapper{
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
}

.hide-overflow{
  overflow: hidden;
}

body{
  width: 100vw;
  background-color: var(--dark);
}
.wrapper{
  width: 100%;
}
.max-height{
  height: 100%;
}
.max-width{
  width: 100%;
}
.half-width{
  width: 45%;

}
.half-height{
  height: 50%;
}
.partial-height{
  height: 33vh;
}
.container{
  display: grid;
  box-sizing: border-box;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 3.5rem;

  grid-template-rows: repeat(24, 1fr);
  grid-row-gap: 0.25rem;

  padding: 3rem 5.5rem;
  height: 100vh;
  border-top: 1px solid var(--dark-grey);
  overflow: hidden;
}
.container.alt{
  background-color: var(--black);
}

.flex-container{
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2rem;
  grid-template-rows: repeat(21, 1fr);
  grid-row-gap: 0.25rem;
  padding: 3rem 5.5rem;
  height: 100vh;
}
.grid{
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 3.5rem;
  padding: 0rem 5.5rem;
  border-top: 1px solid var(--dark-grey);
}
.grid.alt{
  background-color: var(--black);
  border-top: 1px solid var(--dark);
}

.card{
  background-color: var(--green);
  overflow: hidden;
  position: relative;
  padding-top: 3rem;
  margin-bottom: 1rem;
}

#IBP > .card > video{
  width: 70%;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  opacity: 0;
}
#IBP > .card > svg > path{
  opacity: 0;
}
#testnets > .card > div {
  width: 70%;
  margin-bottom: 2rem;

}
#testnets > .card > div > video{
  width: 100%;
  height: 20%;
}
#testnets > .card > div > img {
  width: 10%;
  z-index: 1;
  
}

.supporters{
  padding: 1rem 0rem;
}
.supporters > img{
  max-height: 3rem;
  margin-right: 1rem;
  
}
.border-container{
  box-sizing: border-box;
  border-color: var(--grey);
  border-width: 1px;
  border-style: solid;
  background-color: var(--dark);
  padding: 2rem;
  height: 10rem;
}
.project{
  display: inline-flex;
  height: 100%;
  margin-right: 2rem;
}

.card-container{
  display: flex;
  flex-direction: column;
  border-right: var(--grey) 1px solid;
  padding: 0rem 2rem 2rem 2rem;
  width: 25%;
  height: 25rem;
 
}
.card-container:first-child{
  padding-left: 0rem;
}

.network{
  position: relative;
  width: 24.38rem;
  background-color: var(--dark);
  border:1px solid var(--dark-grey);
  border-radius: 4px;
  height: 8rem;
  overflow: hidden;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}
.network > div{
  padding: 1rem;
}
.network-img{
  position: relative;
  width: 4rem;
  min-width: 4rem;

}
.network-img > img{
  width: 4rem;
  filter: saturate(0) brightness(100);
}
.network:hover{
  cursor: pointer;
}
.stake-icon-container{
  width: 3rem;
  height: 3rem;

}
.stake-icon{
  width: 0%;
  height: 0%;
  overflow: hidden;
  border-radius: 2px;
  opacity: 0;
  background-color: var(--green);
  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0 1px 1px rgba(65, 65, 65, 0.12), 
              0 2px 2px rgba(65, 65, 65, 0.12), 
              0 4px 4px rgba(65, 65, 65, 0.12), 
              0 8px 8px rgba(65, 65, 65, 0.12),
              0 16px 16px rgba(65, 65, 65, 0.12);
}
.stake-icon > img{
  width: 35%;
}
.network:hover > .network-img > .stake-icon-container > .stake-icon, .network:hover > .network-cover{
  height: 100%;
  width: 100%;
  opacity: 0.125;

}
.network:hover > .network-img > .stake-icon-container > .stake-icon{
  opacity: 1;
}
div.network-cover{
  width: 0%;
  height: 0%;
  background-color: var(--light-grey);
  opacity: 0;
  padding: 0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

}
.flex{
  display: flex;
}
.inline-flex{
  display: inline-flex;
}
.center{
  align-items: center;
}
.justify{
  justify-content: center;
}
.flex.between{
  justify-content: space-between;
}
.flex.around{
  justify-content: space-around;
}
.flex.equal-width{
  flex-basis: 0;
  flex-grow: 1;
}
.wrap{
  flex-wrap: wrap;
}
.row{
  flex-direction: row;
}
.col{
  flex-direction: column;
}

.item{
  grid-column-start: 3;
}

.start-1{
  grid-column-start: 1;
}
.start-2{
  grid-column-start: 2;
}
.start-3{
  grid-column-start: 3;
}
.start-4{
  grid-column-start: 4;
}
.start-5{
  grid-column-start: 5;
}
.start-6{
  grid-column-start: 6;
}
.start-7{
  grid-column-start: 7;
}
.start-8{
  grid-column-start: 8;
}
.start-9{
  grid-column-start: 9;
}

.end-4{
  grid-column-end: 4;
}
.end-5{
  grid-column-end: 5;
}
.end-6{
  grid-column-end: 6;
}
.end-7{
  grid-column-end: 7;
}
.end-8{
  grid-column-end: 8;
}
.end-9{
  grid-column-end: 9;
}
.end-10{
  grid-column-end: 10;
}
.end-11{
  grid-column-end: 11;
}
.end-12{
  grid-column-end: 12;
}
.end-13{
  grid-column-end: 13;
}

.row-1{
  grid-row: 1;
  grid-row-end: 24;
}
.row-3{
  grid-row: 3;
  grid-row-end: 24;
}
.row-4{
  grid-row: 4;
  grid-row-end: 24;
}
.row-5{
  grid-row: 5;
  grid-row-end: 24;
}
.row-6{
  grid-row: 6;
  grid-row-end: 24;
}
.row-7{
  grid-row: 7;
  grid-row-end: 24;
}
.row-8{
  grid-row: 8;
  grid-row-end: 25;
}
.row-9{
  grid-row: 9;
  grid-row-end: 24;
}
.row-10{
  grid-row: 10;
  grid-row-end: 24;
}
.row-11{
  grid-row: 11;
  grid-row-end: 24;
}
.row-13{
  grid-row: 13;
  grid-row-end: 24;
}
.row-15{
  grid-row: 15;
  grid-row-end: 24;
}
.row-18{
  grid-row: 18;
  grid-row-end: 24;
}

.margin-top{
  margin-top: 2rem;
}
.margin-bottom{
  margin-bottom: 2rem;
}

.navbar{
  position: fixed;
  width: 100%;
  padding: 0rem 5.5rem;
  height: 9rem;
  z-index: 10;
}
.nav-item{
  font-family: 'Switzer';
  font-size: 1rem;
  font-weight: normal;
  color: var(--grey);
  margin-right: 2rem;
  overflow-y: hidden;
  display: inline-block;
  transition: color 0.25s linear;
}
.nav-item > h4:first-child{
  padding: 0.25rem;
  height: 1rem;
}

.nav-item:hover{
  cursor: pointer;
  color: var(--white);
  
}
.nav-item:hover > .nav-item-hover{
  height: 1rem;
  padding: 0.25rem 0.25rem;;
 
}

.footer{
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 3.5rem;
  background-color: var(--dark);
  z-index:2;
  padding: 2rem 5.5rem;
  height: 50vh;
  border-top: 10px solid var(--green);
  box-sizing: border-box;
}
#footer-logo{
  width: 50%;
}

.align-bottom{
  margin-bottom: 0;
  margin-top: auto;
}
.align-right{
  margin-right: 0;
  margin-left: auto;
}

.button{
  display: inline-block;
  box-sizing: border-box;
  background-color: var(--white);
  color: var(--dark-grey);
  border: 1px solid var(--dark-grey);
  border-radius: 20rem;
  font-family: 'Switzer';
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  width: fit-content;
  height: fit-content;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.button.small{
  display: inline-block;

  padding: 0.25rem 0.75rem;
  margin-right: 1rem;

}
.button:hover{
  background-color: var(--green);
  color: var(--white);
  cursor: pointer;
}
.button > img{
  filter: invert(0);
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);

}
.button:hover > img{
  filter: invert(1);
}
.button-icon{
  width: 1.25rem;
}

.button.alt{
  background-color: transparent;
  border-color: var(--grey);
  border-width: 2px;
  color: var(--grey);
  font-weight: bold;
}
.button.alt:hover{
  border: var(--green) 2px solid;
  background-color: var(--green);
  color: var(--white);
}
.button-container{
  display: block;
  height: 5rem;
  overflow: hidden;
}
.button.dark{
  background-color: var(--dark-grey);
}
.line{
  border-top: var(--dark-grey) 1px solid;
  height: 1px;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}
h4 + .line{
  width: 0%;
}

.morph{
  width: 8vw;
  max-width: 15rem;
  margin-right: 1rem;
}
#landing-video{
  width: 100vw;
  background-color: var(--black);
  bottom: 0;
  left: 0;
  z-index: 0;
}
#landing-video > video{
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;

  
}
video{
  z-index: 0;
}

.modal-wrapper{
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);  
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;

  z-index: 10;

}

.modal{
  width: 864px;
  height: 486px;
  background-color: var(--dark);
  border-radius: 0.25rem;
  border: 1px solid var(--dark-grey);
  box-sizing: border-box;
  overflow: hidden;
}
.modal-body{
  position: absolute;
  max-width: 864px;
  max-height: 486px;
  width: 90%;
  height: 90%;
}

.slider {
  max-width: 864px;
  overflow: hidden;
  width: 90%;
}


.slides > div {
  width: 90%;
  height: 90%;
}

.slides {
  display: flex;
  overflow: hidden;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  border: 1px solid var(--grey);
  border-radius: 4px;
  background-color: var(--dark);
  
}
.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides > div {
  display: flex;
  padding: 2rem;
  scroll-snap-align: start;
  flex-shrink: 0;
  max-width: 864px;
  max-height: 486px;

  width: 100%;
  height: 100%;

  margin-right: 50px;
  
  background: var(--dark);
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  
  box-sizing: border-box;

}


.slider > a {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: 1px solid var(--grey);
  color: var(--grey);
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin: 1rem 0 0.5rem 0;
  position: relative;
  margin-right: 0.5rem;
  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 0rem 0.25rem;
  font-size: 0.85rem;
  font-weight: bold;
}
.slider > a:hover{
  background-color: var(--green);
  color: var(--white);
  border-color: var(--green);
}
.slider > a:active {
  top: 1px;
}
.slider > a:focus {
  background: #000;
}
.modal-step{
  margin-right: 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.modal-video{
  position: relative;
  width: 50%;

  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
  0 2px 2px rgba(0,0,0,0.12), 
  0 4px 4px rgba(0,0,0,0.12), 
  0 8px 8px rgba(0,0,0,0.12),
  0 16px 16px rgba(0,0,0,0.12);
}

@media only screen and (max-width: 1450px) {
  html{
    font-size: 12px;
  }

  .item{
    grid-column-start: 2;
  }
  .end-11{
    grid-column-end: 12;
  }
  .start-5{
    grid-column-start: 2;
  }
  .card-container{
    width: 30%;
  }
}

@media only screen and (max-width: 898px) {
  html{
    font-size: 10px;
  }
  h2{
    font-size: 3rem;
  }
  h1{
    font-size: 4rem;
  }

  .navbar{
    position: absolute;
  }

  .flex.half-width{
    width: 100%;
  }
  .max-height{
    height: 50%;
  }

  .footer{
    height: 25vh;
  }
  .card{
    max-height: 50%;
  }
  .container{
    height: 85vh;
  }
  
}

@media only screen and (max-width: 749px) {
  html{
    font-size: 8px;
  }
  h2{
    font-size: 3rem;
  }
  h1{
    font-size: 4rem;
  }
  .item{
    grid-column-start: 1;
  }
  .end-11{
    grid-column-end: 13;
  }
  .start-6{
    grid-column-start: 1;
  }
  .start-5{
    grid-column-start: 1;
  }

  .card-container{
    width: 5000px;
  }

  .modal{
    width: 90%;
    height: 90%;

  }
  .modal-body{
    width: 90%;
    height: 90%;
  }
  
  .slider {
    width: 90%

  }

  .slides > div {
    width: 90%;
    height: 90%;
  }


}